// @flow
import React from 'react';
import { graphql } from 'gatsby';
import Project from '../components/Project';

const Client = ({ data }) => {
	const project = data.allProject.nodes[0];
	const images = {};

	data.allImageSharp.edges.forEach(img => {
		images[img.node.fluid.originalName] = img.node.fluid;
	});

	const gallery = project.projects.flatMap(project => {
		return project.gallery.flatMap(row => {
			return row.map(img => {
				if (img && img.src && images[img.src]) {
					return {
						border: img.border,
						fluid: images[img.src],
					};
				}

				return null;
			})
		});
	});

	return (
		<Project
			name={project.name}
			location={project.location}
			gallery={gallery}
		/>
	);
};

export default Client;

export const query = graphql`
	query ($slug: String!) {
		allProject(filter: {slug: {eq: $slug}}) {
			nodes {
				name
				location
				slug
				projects {
					slug
					gallery {
						src
						border
					}
				}
			}
		}
		allImageSharp(filter: {fluid: {src: {regex: "/project-/"}}}, sort: {fields: fluid___originalName, order: ASC}) {
			edges {
				node {
					id
					fluid(quality: 90, maxWidth: 1080) {
						base64
						srcWebp
						srcSetWebp
						originalImg
						originalName
						presentationWidth
						presentationHeight
						sizes
						src
						srcSet
						aspectRatio
					}
				}
			}
		}
	}
`;
